import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import IntroHeader from '../../components/IntroHeader';
import ContactForm from '../../components/ContactForm';

import styles from './styles.module.less';

const ContactUs = () => {
   const header = <Header active={['tools-and-resources', 'contact-us']} theme="blue-ribbon" />;
   const introHeader = 'Get in touch';
   const par = (
      <span>
         We’re committed to communication and helping our customers
         <br className={styles.mobileHide} /> in any way we can. Check out our{' '}
         <Link to="/home-loan-faqs" className={styles.orangeLink}>
            FAQ page
         </Link>{' '}
         for answers to the most
         <br className={styles.mobileHide} /> commonly asked questions. If you can’t find what you’re looking for,
         <br className={styles.mobileHide} /> feel free to reach us through the form below.
      </span>
   );

   return (
      <Layout hasHero={false} header={header}>
         <Helmet>
            <title>Contact Bay Equity Home Loans | We’re Here To Get You Home</title>
            <meta
               name="description"
               content="Bay Equity is dedicated to communication. If you can’t find the answers to your home loan questions on our FAQs page, reach out to us through the form on this page."
            />
         </Helmet>
         <div className={styles.contact}>
            <IntroHeader header={introHeader} par={par} />
            <ContactForm />
         </div>
      </Layout>
   );
};
export default ContactUs;
